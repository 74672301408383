.aviso-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
  }
  
  .aviso-header, .aviso-footer {
    background-color: #000080; 
    color: white;
    text-align: center;
    padding: 1rem;
  }
  
  .aviso-logo {
    height: 50px;
  }
  
  .aviso-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
  }
  
  .aviso-main h1 {
    margin-bottom: 1rem;
  }
  
  .aviso-main p {
    font-size: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .aviso-main {
      padding: 1rem;
    }
  
    .aviso-main h1 {
      font-size: 1.5rem;
    }
  
    .aviso-main p {
      font-size: 1rem;
    }
  
    .aviso-logo {
      height: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .aviso-main {
      padding: 0.5rem;
    }
  
    .aviso-main h1 {
      font-size: 1.2rem;
    }
  
    .aviso-main p {
      font-size: 0.9rem;
    }
  
    .aviso-logo {
      height: 30px;
    }
  }