.comunicacao-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    background-color: #f3f2f1;
    color: #323130;
    font-family: 'Segoe UI';

  }
  
  .button-close{
    border-radius: 10px;
    width: 50%;
    color: #ffffff;
    background-color: #ad0707;
}

.page-title,
.page-subtitle,
.page-paragraph,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: rgb(100, 100, 100);
    justify-content: center;
    align-items: center;
}

.image-start{
  display:flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 100%;
}

.modal-message{
  color: rgb(0, 0, 0);
  justify-content: center;
  text-align: justify;
  align-items: center;
  display:flex;
  justify-items: center;
  padding-bottom: 15px;
}
.modal-buttons{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
