
  .autenticacao-icon {
    margin-right: 10px;
    flex-direction: row;
  }
  
  .autenticacao-logo {
    margin-bottom: 20px;
    flex-direction: row;
  }
  
  .autenticacao-mensagem {
    max-width: 90%;
    text-align: center;
  }

  .container-error {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 90vh;
    background-color: #100579;
    color: #fafcff;
    font-family: 'Segoe UI';
    padding: 50px;
}
