.button-submit {
    border-radius: 10px;
    width: 150px;
    color: #ffffff;
    background-color: #000080;
}

.feedback {
    width: 50%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #161616;
    border-radius: 20px;
    background-color: #ffffff;
    resize: none;
    margin-top: 20px;
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: space;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}

.feedback-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
}

.feedback-header,
.feedback-footer {
    background-color: #000080;
    /* Azul */
    color: white;
    text-align: center;
    padding: 1rem;
}

.feedback-logo {
    height: 50px;
}

.feedback-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}

.feedback-main h1 {
    margin-bottom: 1rem;
}

.feedback-main p {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .feedback-main {
        padding: 1rem;
    }

    .feedback-main h1 {
        font-size: 1.5rem;
    }

    .feedback-main p {
        font-size: 1rem;
    }

    .feedback-logo {
        height: 40px;
    }


    .row {
        display: flex;
        flex-direction: column;
        justify-content: space;
        align-items: center;
        flex-wrap: wrap;
        width: 80%;
        margin-top: 20px;
    }

    .button-submit {
        border-radius: 10px;
        width: 50%;
        color: #ffffff;
        background-color: #100579;
    }

    .feedback {
        width: 100%;
        height: 100px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 2px solid #161616;
        border-radius: 20px;
        background-color: #ffffff;
        resize: none;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .feedback-main {
        padding: 0.5rem;
    }

    .feedback-main h1 {
        font-size: 1.2rem;
    }

    .feedback-main p {
        font-size: 0.9rem;
    }

    .feedback-logo {
        height: 30px;
    }

    .row {
        display: flex;
        flex-direction: column;
        justify-content: space;
        align-items: center;
        flex-wrap: wrap;
        width: 80%;
        margin-top: 20px;
    }

    .button-submit {
        border-radius: 10px;
        width: 50%;
        color: #ffffff;
        background-color: #100579;
    }

    .feedback {
        width: 100%;
        height: 100px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 2px solid #161616;
        border-radius: 20px;
        background-color: #ffffff;
        resize: none;
        margin-top: 20px;
    }

}