.loading {
    margin-left: 40%;
}

.btn-blue {
    color: #f5f5f5;
    background-color: #102a46;
    border-radius: 16px;
    align-items: "center";
}

.buttonsModalM {
    margin-left: 40%;
}

.exclusao-btn-white {
    background-color: #f5f5f5;
    color: #102a46;
    border-radius: 16px;
    margin: 16px 8px 16px 8px;
}

.container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    background-color: #ffffff;
    color: #323130;
    font-family: 'Segoe UI';
    overflow: hidden;
}

@media (min-width: 769px) {
    .page-header-logo {
        width: 100px;
        height: 50px;
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .page-header-logo {
        width: 100px;
        height: 50px;
        margin-bottom: 5px;
    }
}